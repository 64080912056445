/*
 * Component: Sidebar Mini
 */

//Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  //Sidebar mini should work only on devices larger than @screen-sm
  @media (min-width: @screen-sm) {
    //When the sidebar is collapsed...
    &.sidebar-collapse {

      //Apply the new margining to the main content and footer
      .content-wrapper,
      .right-side,
      .main-footer {
        margin-left: 50px!important;
        z-index: 840;
      }

      //Modify the sidbar to shrink instead of disappearing
      .main-sidebar {
        //Don't go away! Just shrink
        .translate(0, 0);
        width: 50px!important;
        z-index: 850;

        //Keep only the image of the user in the user panel
        .user-panel {
          > .info {
            display: none;
          }
        }
      }

      .sidebar-menu {
        //Make the sidebar links, menus, labels, badeges
        //and angle icons disappear
        > li {
          position: relative;
          > a {
            margin-right: 0;
          }
          > a > span,
          > .treeview-menu,
          > a > .pull-right,
          &.header {
            display: none!important;
          }

          > a > span {
            border-top-right-radius: 4px;
          }

          &:not(.treeview) {
            > a > span {
              border-bottom-right-radius: 4px;
            }
          }

          > .treeview-menu {
            //Add some padding to the treeview menu
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom-right-radius: 4px;
          }

          //Show menu items on hover
          &:hover {
            > a > span:not(.pull-right),
              > .treeview-menu {
              display: block!important;
              position: absolute;
              width: @sidebar-width - 50;
              left: 50px;
            }

            //position the header & treeview menus
            > a > span {
              top: 0;
              padding: 12px 5px 12px 20px;
              background-color: inherit;
            }
            > .treeview-menu {
              top: 44px;
              margin-left: 0;
            }
          }
        }
      }

      //One more thing to remove because it doesn't fit :-z
      .sidebar-form {
        display: none;
      }

      .main-header {
        //Lets make the logo also shrink and the mini logo to appear
        .logo {
          width: 50px;
          > .logo-mini {
            display: block;
            margin-left: -15px;
            margin-right: -15px;
            font-size: 18px;
          }
          > .logo-lg {
            display: none;
          }
        }

        //Of course, since the logo got smaller, we need to fix the
        //navbar's position
        .navbar {
          margin-left: 50px;
        }
      }
    }
  }
}