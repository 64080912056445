/*
 * Component: Control sidebar. By deafult, this is the right sidebar.
 */
//The sidebar's backhround control class
//This is a hack to make the background visible while scrolling
.control-sidebar-bg {
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  width: @control-sidebar-width;
  background: @sidebar-dark-bg;
}

//Transitions
.control-sidebar-bg,
.control-sidebar {
  .translate(@control-sidebar-width, 0);
  .transition-transform(@transition-speed ease-in-out);
}
.control-sidebar {
  position: absolute;
  top: @navbar-height;
  right: 0;
  width: @control-sidebar-width;
  z-index: 1010;  
  color: @sidebar-dark-color;
  //Fix position after header collapse
  @media (max-width: @screen-sm) {
    top: @navbar-height + 50;    
  }
  //Tab panes
  > .tab-content {
    padding: 10px 15px;
  }
  //Open state with slide over content effect
  &.control-sidebar-open {
    &,
    + .control-sidebar-bg {
      .translate(0, 0);
    }
  }
}
//Open without silde over content
.control-sidebar-open {
  .control-sidebar-bg,
  .control-sidebar {
    .translate(0,0);
  }
  @media(min-width: @screen-sm) {
    .content-wrapper,
    .right-side,
    .main-footer {
      margin-right: @control-sidebar-width;
    }
  }
}


//Control sidebar tabs
.control-sidebar-tabs {
  border-bottom: darken(@sidebar-dark-bg, 3%);
  > li {
    > a {
      .border-radius(0)!important;
      background: darken(@sidebar-dark-bg, 5%);
      color: @sidebar-dark-color;
      //Hover and active states
      &,
      &:hover {
        border-top: none;
        border-right: none;
        border-left: 1px solid darken(@sidebar-dark-bg, 7%)!important;
        border-bottom: 1px solid darken(@sidebar-dark-bg, 7%)!important;
      }
      &:hover,
        &:focus,
        &:active {
        background: darken(@sidebar-dark-bg, 3%);
      }
      .icon {
        font-size: 16px;
      }
    }
    //Active state
    &.active {
      > a {
        &,
        &:hover,
          &:focus,
          &:active {
          border-top: none!important;
          border-right: none!important;
          border-bottom: none!important;
          background: @sidebar-dark-bg;
          color: #fff;
        }
      }
    }
  }
  //Remove resposiveness on small screens
  @media(max-width: @screen-sm) {
    display: table;
    >li {
      display: table-cell!important;
    }
  }
}
//Headings in the sidebar content
.control-sidebar-heading {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}
//Subheadings
.control-sidebar-subheading {
  display: block;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
//Control Sidebar Menu
.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  > li > a {
    .clearfix();
    display: block;
    padding: 10px 15px;
    &:hover {
      background: @sidebar-dark-hover-bg;
    }
    > .control-sidebar-subheading {
      margin-top: 0;
    }
  }
  .menu-icon {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
  }
  .menu-info {
    margin-left: 45px;
    margin-top: 3px;
    > .control-sidebar-subheading {
      margin: 0;
    }
    > p {
      margin: 0;
      color: @sidebar-dark-color;
      font-size: 11px;
    }
  }  
  .progress {
    margin: 0;
  }
}