@import "AdminLTE/AdminLTE.less";
@import "vendor/bower_components/font-awesome/less/font-awesome.less";
@import "vendor/bower_components/ionicons/less/ionicons.less";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}
.form-control{
  color: #0E0F0F;
}
