/*
 * Page: 400 and 500 error pages
 * ------------------------------
 */
.error-page {
  width: 600px;
  margin: 20px auto 0 auto;
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }
  //For the error number e.g: 404
  > .headline {
    float: left;
    font-size: 100px;
    font-weight: 300;
    @media (max-width: @screen-sm-max) {
      float: none;
      text-align: center;
    }
  }
  //For the message
  > .error-content {
    margin-left: 190px;
    @media (max-width: @screen-sm-max) {
      margin-left: 0;
    }
    > h3 {
      font-weight: 300;
      font-size: 25px;
      @media(max-width: @screen-sm-max) {
        text-align: center;
      }
    }
    display: block;
  }
}